<template>
  <FormComponent @submit="resetPassword">
    <h3 class="auth-title">
      {{ $t('app.reset_password') }}
    </h3>
    <template v-if="loaded">
      <PasswordInputComponent v-model="form.password"
                              :label="$t('reset-password.type-password')"
                              :placeholder="$t('login-invite.fullname')"
                              required/>
      <ButtonComponent :loading="isSending" is-block type="submit">
        {{ $t('app.reset_password') }}
      </ButtonComponent>
      <div class="checkbox">
        <router-link class="yup-purple-color" to="/login">
          {{ $t('reset-password.to-login') }}
        </router-link>
      </div>
    </template>
  </FormComponent>
</template>

<script>
import FormComponent from '@/components/form/FormComponent.vue';
import PasswordInputComponent from '@/components/form/PasswordInputComponent.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';

export default {
  name: 'ResetVerification',
  components: {
    ButtonComponent,
    PasswordInputComponent,
    FormComponent,
  },
  data() {
    return {
      loaded: false,
      form: {
        password: '',
        token: '',
      },
      isSending: false,
    };
  },
  created() {
    this.fetchToken();
  },
  methods: {
    fetchToken() {
      this.$store
        .dispatch('auth/resetVerification', this.$route.params.token)
        .then((response) => {
          this.form.token = response.token;
        })
        .finally(() => {
          this.loaded = true;
        });
    },
    resetPassword() {
      this.isSending = true;
      this.$store
        .dispatch('auth/resetPassword', this.form)
        .then(() => {
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('reset-password.successs'),
            type: 'success',
          });
          this.$router.push('/login');
        })
        .finally(() => {
          this.isSending = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.social-group {
  gap: 16px;
}

.auth-title {
  font-size: 36px;
  margin-bottom: 26px;

  .btn {
    padding: 0;
    font-size: 24px;
  }
}

.checkbox {
  margin-top: 26px;
  text-align: center;
  margin-bottom: 26px;
  font-weight: 500;
}
</style>
